import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from './cookie.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn$ = new BehaviorSubject<boolean>(false)
  userContext$ = new BehaviorSubject<{ user: string, session: string } | undefined>(undefined);


  constructor(
    private cookie: CookieService,
  ) {
    if (
      this.invalidPopulation(this.cookie.getByName('x-isure-auth'))
    ) {
      this.flushLoginConditions()
    } else {
      this.isLoggedIn$.next(true)
      this.setUserContext()
    }
  }

  private invalidPopulation(value: any) {
    return value == undefined || value === '' || value === null
  }

  flushLoginConditions() {
    this.isLoggedIn$.next(false)
    this.cookie.setCookie('x-isure-auth', '', false, '', 'Thu, 01 Jan 1970 00:00:01 GMT')
  }
  setUserContext() {
    var authCookie: string = this.cookie.getByName("x-isure-auth")
    if (authCookie !== undefined) {
      var payload = JSON.parse(atob(authCookie.split(".")[1].toString()))
      this.userContext$.next({
        user: payload["user"],
        session: payload["session"]
      })

    }
  }
}
