import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { SettingsComponent } from './settings/settings.component';
import { IsureProductSelectorComponent } from './isure-product-selector/isure-product-selector.component';

const routes: Routes = [

  {
    path:'product',
    loadChildren: () => import('./isure-product-configurator/isure-product-configurator.module').then(m => m.IsureProductConfiguratorModule),
    canActivate: [AuthGuard]
  },
  {
    path:'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    
  },
  {
    path:'preferences',
    component: PreferencesComponent,
    canActivate: [AuthGuard],

  },
  {
    path:'404', component: ForbiddenComponent 
  },
  {
    path:'',
    loadChildren: () => import('./isure-product-selector/isure-product-selector.module').then(m => m.IsureProductSelectorModule),
    canActivate: [AuthGuard]
  },
  {
    path:'**',  redirectTo:''
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
