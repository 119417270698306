import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input("title") title: string
  @Input("userName") userName: string
  dropDownActive: boolean = false

  userImagePath: string = "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1180&q=80"

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }


  activateDropDown = () => {
    this.dropDownActive = !this.dropDownActive
  }

  navigateTo = (route: string) => {
    this.router.navigate([route])
  }
}
