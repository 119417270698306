
<section>

    <form>
        <h2>Preferences</h2>
        <div class="isure-form-bundle">
            <div class="isure-form-group">
                <label for="">Source</label>
                <select name="source" id="">
                    <option value="0" disabled selected>Select Option</option>
                </select>
            </div>
            <div class="isure-form-group">
                <label for="">Environment</label>
                <select name="environment" id="">
                    <option value="0" disabled selected>Select Option</option>
                </select>
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
        </div>
        <hr>
        <div class="isure-form-bundle">
            <div class="isure-form-group">
                <label for="">Source</label>
                <select name="source" id="">
                    <option value="0" disabled selected>Select Option</option>
                </select>
            </div>
            <div class="isure-form-group">
                <label for="">Environment</label>
                <select name="environment" id="">
                    <option value="0" disabled selected>Select Option</option>
                </select>
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
            <div class="isure-form-group">
                <label for="">Setting</label>
                <input type="text" name="setting" placeholder="Setting" />
            </div>
        </div>
        <button mat-button>Save</button>
    </form>

</section>