import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

 
  getAll = () => {
    var cookies = document.cookie.split(';')
    var cookieObj: any = {}
    cookies.forEach(cookie => {
      var [key, value] = cookie.trim().split('=')
      cookieObj[key] = value
    })
    return cookieObj
  }

  getByName = (cookie: string) => {
    var search = this.getAll()[cookie]
    return search == undefined ? undefined : search
  }

  setCookie = (key: string, value: string, secure: boolean = false, domain: string = '', expires: string = 'Session') => {
    document.cookie = `${key}=${value}; Secure = ${secure};  Domain = ${domain}; Expires = ${expires}; SameSite=None;`
  }
}
