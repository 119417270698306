<mat-toolbar>
    <span (click)="navigateTo('/')">{{title}}</span>
    <div class="nav-commands">
        <button mat-button (click)="activateDropDown()">
            <img [src]="userImagePath" />
            <span>{{userName}}</span>
            <mat-icon [style.transform]="dropDownActive ? 'rotateX(180deg)' : 'rotateX(0deg)'" [style.transition]="'.2s'" >keyboard_arrow_down</mat-icon>
        </button>

        <div class="dropdown"  [ngStyle]="{maxHeight: dropDownActive ? '300px' : '0'}">
            <button *ngIf="dropDownActive" mat-button class="example-icon" routerLink="/settings">
                <mat-icon>person</mat-icon>
                <span>User Settings</span>
            </button>
            <button *ngIf="dropDownActive" mat-button class="example-icon" routerLink="/preferences">
                <mat-icon>settings</mat-icon>
                <span>Preferences</span>
            </button>
            <button *ngIf="dropDownActive" mat-button class="example-icon" routerLink="/preferences">
                <mat-icon>power_settings_new</mat-icon>
                <span>Log Out</span>
            </button>
        </div>
    </div>

</mat-toolbar>