import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, filter, map, Observable, of, Subscribable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ServerEventService } from './server-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = environment.env === "PROD" ? "iSureEditor2.0" : "iSure® Editor";

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.auth.isLoggedIn$.subscribe(res => {
      if (res === false) {
        window.location.href = environment.redirect + "/login?redirectUrl=" + window.location.href
      }
    })
  }
}