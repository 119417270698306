    
    <h2 mat-dialog-title>New Product</h2>
    <mat-dialog-content >
        <mat-form-field>
            <mat-label>
                Product Name
            </mat-label>
            <input matInput #name/>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput #description></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="dialogRef.close()">Close</button>
        <button mat-button [mat-dialog-close]="{name: name.value, description: description.value}" cdkFocusInitial>Create Product</button>
    </mat-dialog-actions>