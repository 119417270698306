import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-prod-dialog',
  templateUrl: './new-prod-dialog.component.html',
  styleUrls: ['./new-prod-dialog.component.css']
})
export class NewProdDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewProdDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

}
